import React, { useState, useEffect } from 'react'
import { FiAlertTriangle, FiClock, FiUser, FiMessageSquare, FiFilter, FiSearch, FiX, FiTrash2 } from 'react-icons/fi'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ReportsSection.css'
import { useAuth } from '../../../context/AuthContext'

const ReportsSection = () => {
	const { user } = useAuth()
	const [reports, setReports] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')

	const fetchReports = async () => {
		try {
			setLoading(true)
			const response = await axios.get('https://findalike.me/api/admin/reports', {
				headers: {
					'X-User-Id': user?._id,
				},
				withCredentials: true,
			})
			setReports(response.data)
		} catch (error) {
			console.error('Error fetching reports:', error)
			setError('Failed to fetch reports')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchReports()
	}, [])

	const formatDate = date => {
		return date ? new Date(date).toLocaleString() : 'N/A'
	}

	const getSeverityClass = category => {
		const severityMap = {
			harassment: 'high',
			hate_speech: 'high',
			inappropriate: 'medium',
			spam: 'low',
			impersonation: 'high',
			other: 'medium',
		}
		return severityMap[category] || 'medium'
	}

	const getCategoryBackground = category => {
		const colors = {
			harassment: 'var(--danger-light)',
			spam: 'var(--warning-light)',
			inappropriate: 'var(--purple-light)',
			hate_speech: 'var(--danger-light)',
			impersonation: 'var(--info-light)',
			default: 'var(--primary-light)',
		}
		return colors[category] || colors.default
	}

	const getCategoryColor = category => {
		const colors = {
			harassment: 'var(--danger)',
			spam: 'var(--warning)',
			inappropriate: 'var(--purple)',
			hate_speech: 'var(--danger)',
			impersonation: 'var(--info)',
			default: 'var(--primary)',
		}
		return colors[category] || colors.default
	}

	const formatCategory = category => {
		return category ? category.charAt(0).toUpperCase() + category.slice(1).replace(/_/g, ' ') : 'Unknown'
	}

	const handleDismissReport = async reportId => {
		try {
			await axios.delete(`https://findalike.me/api/admin/reports/${reportId}/dismiss`, { withCredentials: true })

			// Remove the report from the local state
			setReports(reports.filter(report => report._id !== reportId))
			toast.success('Report dismissed successfully')
		} catch (error) {
			console.error('Error dismissing report:', error)
			toast.error('Failed to dismiss report')
		}
	}

	const handleDeleteUser = async (userId, reportId) => {
		if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
			return
		}

		try {
			const reason = prompt('Please enter the reason for blacklisting this user:')
			if (!reason) return

			await axios.delete(`https://findalike.me/api/admin/users/${userId}/delete-and-blacklist`, {
				headers: {
					'X-User-Id': user?._id,
				},
				withCredentials: true,
				data: {
					reportId,
					reason,
				},
			})

			// Remove the report from the local state
			setReports(prevReports => prevReports.filter(report => report._id !== reportId))
			toast.success('User has been deleted and blacklisted successfully')
		} catch (error) {
			console.error('Error deleting user:', error)
			toast.error(error.response?.data?.message || 'Failed to delete user')
		}
	}

	const filteredReports = reports?.filter(
		report =>
			report.reportedUser?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
			report.reportedUser?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
			report.reason?.toLowerCase().includes(searchTerm.toLowerCase()) ||
			report.category?.toLowerCase().includes(searchTerm.toLowerCase())
	)

	return (
		<div className="reports-section">
			<div className="reports-header">
				<h2 className="section-title">User Reports</h2>
				<div className="search-container">
					<FiSearch className="search-icon" />
					<input
						type="text"
						placeholder="Search reports..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						className="search-input"
					/>
				</div>
			</div>

			{loading && <div className="loading">Loading...</div>}
			{error && <div className="error">{error}</div>}

			<div className="reports-grid">
				{filteredReports?.length === 0 ? (
					<div className="no-reports">{searchTerm ? 'No reports found matching your search' : 'No reports found'}</div>
				) : (
					filteredReports?.map(report => (
						<div key={report._id} className="report-card">
							<div className="report-header">
								<div className={`report-severity ${getSeverityClass(report.category)}`}>
									<FiAlertTriangle />
									{formatCategory(report.category)}
								</div>
								<div className="report-timestamp">
									<FiClock />
									{formatDate(report.createdAt)}
								</div>
							</div>

							<div className="report-content">
								<div className="involved-users">
									<div className="user-profile">
										<div className="user-info">
											<img src={report.reportedBy?.profilePicture || '/default-avatar.png'} alt="" className="avatar" />
											<div className="user-details">
												<h4>{report.reportedBy?.name || 'Unknown User'}</h4>
												<p>{report.reportedBy?.email || 'No email'}</p>
											</div>
										</div>
									</div>

									<div className="user-profile reported">
										<div className="user-info">
											<img
												src={report.reportedUser?.profilePicture || '/default-avatar.png'}
												alt=""
												className="avatar"
											/>
											<div className="user-details">
												<h4>{report.reportedUser?.name || 'Unknown User'}</h4>
												<p>{report.reportedUser?.email || 'No email'}</p>
											</div>
										</div>
									</div>
								</div>

								<div className="report-reason">
									<div className="reason-label">Reason for Report</div>
									<p className="reason-text">{report.reason || 'No reason provided'}</p>
								</div>
							</div>

							{report.chatHistory && report.chatHistory.length > 0 && (
								<div className="report-chat-history">
									<div className="chat-history-label">Chat History</div>
									<div className="chat-messages">
										{report.chatHistory.map((message, index) => {
											const senderData = message.sender || {}
											return (
												<div
													key={index}
													className={`chat-message ${
														senderData._id === report.reportedUser?._id ? 'reported-user-message' : 'reporter-message'
													}`}>
													<div className="message-header">
														<img
															src={senderData.profilePicture || '/default-avatar.png'}
															alt={`${senderData.name || 'Unknown'}'s avatar`}
															className="message-avatar"
															onError={e => {
																e.target.src = '/default-avatar.png'
															}}
														/>
														<span className="message-sender">{senderData.name || 'Unknown User'}</span>
														<span className="message-time">{new Date(message.createdAt).toLocaleString()}</span>
													</div>
													<div className="message-content">
														{message.isDeleted ? (
															<span className="deleted-message">Message was deleted</span>
														) : (
															message.content
														)}
													</div>
												</div>
											)
										})}
									</div>
								</div>
							)}

							<div className="report-actions">
								<button className="action-button dismiss-button" onClick={() => handleDismissReport(report._id)}>
									<FiX />
									Dismiss
								</button>
								<button
									className="action-button delete-button"
									onClick={() => handleDeleteUser(report.reportedUser._id, report._id)}>
									<FiTrash2 />
									Delete User & Blacklist
								</button>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}

export default ReportsSection
