import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import DashboardNav from './components/DashboardNav'
import Overview from './components/Overview'
import FriendshipsSection from './components/FriendshipsSection'
import ReportsSection from './components/ReportsSection'
import UsersSection from './components/UsersSection'
import BlacklistSection from './components/BlacklistSection'
import ExpertsSection from './components/ExpertsSection'
import './AdminPanel.css'

const AdminPanel = () => {
	const { user } = useAuth()
	const [activeSection, setActiveSection] = useState('overview')

	const renderSection = () => {
		switch (activeSection) {
			case 'overview':
				return <Overview />
			case 'friendships':
				return <FriendshipsSection />
			case 'reports':
				return <ReportsSection />
			case 'users':
				return <UsersSection />
			case 'experts':
				return <ExpertsSection />
			case 'blacklist':
				return <BlacklistSection />
			default:
				return <Overview />
		}
	}

	return (
		<div className="admin-wrapper">
			<div className="admin-dashboard">
				<DashboardNav activeSection={activeSection} setActiveSection={setActiveSection} />
				<main className="dashboard-main">
					<div className="dashboard-content">{renderSection()}</div>
				</main>
			</div>
		</div>
	)
}

export default AdminPanel
