import React, { useState, useEffect } from 'react'
import { adminApi } from '../../../utils/adminApi'
import { IoCheckmark, IoClose, IoTime } from 'react-icons/io5'
import ActivationModal from './ActivationModal'
import './ExpertsSection.css'

const ExpertsSection = () => {
	const [experts, setExperts] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [selectedExpert, setSelectedExpert] = useState(null)

	useEffect(() => {
		fetchExperts()
	}, [])

	const fetchExperts = async () => {
		try {
			const data = await adminApi.getExperts()
			setExperts(data)
		} catch (error) {
			setError('Failed to fetch experts')
			console.error('Error fetching experts:', error)
		} finally {
			setLoading(false)
		}
	}

	const handleActivation = async days => {
		try {
			await adminApi.updateExpertStatus(selectedExpert._id, true, days)
			setShowModal(false)
			fetchExperts()
		} catch (error) {
			console.error('Error activating expert:', error)
		}
	}

	const handleDeactivation = async expertId => {
		try {
			await adminApi.updateExpertStatus(expertId, false)
			fetchExperts()
		} catch (error) {
			console.error('Error deactivating expert:', error)
		}
	}

	const renderExpertCard = expert => {
		const expirationDate =
			expert.activeTime && expert.activationDuration
				? new Date(new Date(expert.activeTime).getTime() + expert.activationDuration * 24 * 60 * 60 * 1000)
				: null

		return (
			<div key={expert._id} className="expert-card">
				<div className="expert-header">
					<div className="expert-header-content">
						<div className="expert-image-container">
							<img src={expert.image || 'default-avatar.png'} alt={expert.name} className="expert-avatar" />
						</div>
						<div className="expert-title">
							<h3>{expert.name}</h3>
							<span className={`status-badge ${expert.isActive ? 'active' : 'inactive'}`}>
								{expert.isActive ? 'Active' : 'Inactive'}
							</span>
						</div>
					</div>
				</div>

				<div className="expert-info">
					<div className="info-group">
						<div className="info-item">
							<span className="info-label">Expert ID</span>
							<span className="info-value">{expert.expertId || expert._id.substring(0, 6)}</span>
						</div>
						<div className="info-item">
							<span className="info-label">Email</span>
							<span className="info-value">{expert.email}</span>
						</div>
					</div>

					<div className="info-group">
						<div className="info-item">
							<span className="info-label">Service Type</span>
							<span className="info-value capitalize">{expert.serviceType || 'Not specified'}</span>
						</div>
						<div className="info-item">
							<span className="info-label">Experience</span>
							<span className="info-value">{expert.experience || 0} years</span>
						</div>
					</div>

					<div className="info-item full-width">
						<span className="info-label">Languages</span>
						<div className="tags-container">
							{expert.languages?.map(lang => (
								<span key={lang} className="tag">
									{lang}
								</span>
							)) || 'Not specified'}
						</div>
					</div>

					<div className="info-item full-width">
						<span className="info-label">Specializations</span>
						<div className="tags-container">
							{expert.specializations?.map(spec => (
								<span key={spec} className="tag">
									{spec}
								</span>
							)) || 'Not specified'}
						</div>
					</div>

					{expert.contentTitle && (
						<div className="info-item full-width">
							<span className="info-label">Content Title</span>
							<span className="info-value">{expert.contentTitle}</span>
						</div>
					)}

					<div className="info-item full-width">
						<span className="info-label">Redirect Link</span>
						<a href={expert.redirectLink} target="_blank" rel="noopener noreferrer" className="link-value">
							{expert.redirectLink}
						</a>
					</div>

					{expert.isActive && expirationDate && (
						<div className="info-item">
							<span className="info-label">Expires</span>
							<span className="info-value highlight">{expirationDate.toLocaleDateString()}</span>
						</div>
					)}
				</div>

				<div className="expert-actions">
					{expert.isActive ? (
						<button onClick={() => handleDeactivation(expert._id)} className="status-toggle deactivate">
							Deactivate
						</button>
					) : (
						<button
							onClick={() => {
								setSelectedExpert(expert)
								setShowModal(true)
							}}
							className="status-toggle activate">
							Activate
						</button>
					)}
				</div>
			</div>
		)
	}

	if (loading) return <div className="loading">Loading experts...</div>
	if (error) return <div className="error">{error}</div>

	return (
		<div className="experts-section">
			<h2>Expert Accounts</h2>
			<div className="experts-grid">{experts.map(expert => renderExpertCard(expert))}</div>

			{showModal && (
				<ActivationModal
					isOpen={showModal}
					onClose={() => {
						setShowModal(false)
						setSelectedExpert(null)
					}}
					onActivate={handleActivation}
					expertName={selectedExpert?.name}
				/>
			)}
		</div>
	)
}

export default ExpertsSection
