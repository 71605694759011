import React, { useState, useEffect } from 'react'
import { FiTrash2, FiSearch, FiAlertCircle } from 'react-icons/fi'
import axios from 'axios'
import { toast } from 'react-toastify'
import './BlacklistSection.css'
import { useAuth } from '../../../context/AuthContext'

const BlacklistSection = () => {
	const { user } = useAuth()
	const [blacklist, setBlacklist] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')

	const fetchBlacklist = async () => {
		try {
			setLoading(true)
			const response = await axios.get('https://findalike.me/api/admin/blacklist', {
				withCredentials: true,
				headers: {
					'X-User-Id': user?._id,
				},
			})
			setBlacklist(response.data)
		} catch (error) {
			console.error('Error fetching blacklist:', error)
			setError('Failed to fetch blacklist')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchBlacklist()
	}, [])

	const handleRemoveFromBlacklist = async email => {
		if (!window.confirm('Are you sure you want to remove this email from the blacklist?')) {
			return
		}

		try {
			await axios.delete(`https://findalike.me/api/admin/blacklist/${encodeURIComponent(email)}`, {
				withCredentials: true,
				headers: {
					'X-User-Id': user?._id,
				},
			})

			setBlacklist(blacklist.filter(item => item.email !== email))
			toast.success('Email removed from blacklist')
		} catch (error) {
			console.error('Error removing from blacklist:', error)
			toast.error('Failed to remove from blacklist')
		}
	}

	const filteredBlacklist = blacklist.filter(item => item.email.toLowerCase().includes(searchTerm.toLowerCase()))

	if (loading) return <div className="loading">Loading...</div>
	if (error) return <div className="error">{error}</div>

	return (
		<div className="blacklist-section">
			<div className="blacklist-header">
				<div className="title-section">
					<FiAlertCircle className="blacklist-icon" />
					<h2>Blacklisted Users</h2>
					<span className="blacklist-count">{blacklist.length} total</span>
				</div>
				<div className="search-container">
					<FiSearch className="search-icon" />
					<input
						type="text"
						placeholder="Search by email..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						className="search-input"
					/>
				</div>
			</div>

			<div className="blacklist-table-container">
				<table className="blacklist-table">
					<thead>
						<tr>
							<th>Email</th>
							<th>Reason</th>
							<th>Banned Date</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{filteredBlacklist.map(item => (
							<tr key={item.email}>
								<td>{item.email}</td>
								<td>{item.reason}</td>
								<td>{new Date(item.bannedAt).toLocaleDateString()}</td>
								<td>
									<button
										onClick={() => handleRemoveFromBlacklist(item.email)}
										className="remove-button"
										title="Remove from blacklist">
										<FiTrash2 />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default BlacklistSection
