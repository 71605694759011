import React, { useState, useEffect } from 'react'
import {
	BarChart,
	Bar,
	PieChart,
	Pie,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { FiUsers, FiHeart, FiClock } from 'react-icons/fi'
import axios from 'axios'
import './FriendshipsSection.css'
import { useAuth } from '../../../context/AuthContext'

const FriendshipsSection = () => {
	const { user } = useAuth()
	const [stats, setStats] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		fetchFriendshipStats()
	}, [])

	const fetchFriendshipStats = async () => {
		try {
			const response = await axios.get('https://findalike.me/api/admin/friendship-stats', {
				headers: {
					'X-User-Id': user?._id,
				},
				withCredentials: true,
			})
			setStats(response.data)
		} catch (error) {
			console.error('Error fetching friendship stats:', error)
			setError('Failed to load friendship statistics')
		} finally {
			setLoading(false)
		}
	}

	if (loading)
		return (
			<div className="loading-container">
				<span className="loading-text">Loading friendships...</span>
			</div>
		)
	if (error) return <div className="error-message">{error}</div>

	const COLORS = ['#4f46e5', '#3b82f6', '#06b6d4', '#10b981', '#8b5cf6']

	return (
		<div className="friendships-section">
			<div className="stats-cards">
				<div className="stat-card">
					<div className="stat-icon-wrapper">
						<FiHeart className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>Total Friendships</h3>
						<p>{stats.total.toLocaleString()}</p>
					</div>
				</div>

				<div className="stat-card">
					<div className="stat-icon-wrapper new">
						<FiClock className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>New Friendships (24h)</h3>
						<p>{stats.new24h.toLocaleString()}</p>
					</div>
				</div>

				<div className="stat-card">
					<div className="stat-icon-wrapper popular">
						<FiUsers className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>Most Popular Category</h3>
						<p>{stats.mostPopular.category}</p>
					</div>
				</div>
			</div>

			<div className="charts-grid">
				<div className="chart-container">
					<h3>Friendship Categories</h3>
					<div className="categories-list">
						{stats.categories.map((category, index) => (
							<div key={index} className="category-item">
								<div className="category-header">
									<span className="category-name">{category.category}</span>
									<span className="category-count">
										{category.count.toLocaleString()}
										<span className="percentage">({((category.count / stats.total) * 100).toFixed(1)}%)</span>
									</span>
								</div>
								<div className="category-bar-container">
									<div
										className="category-bar"
										style={{
											width: `${(category.count / stats.total) * 100}%`,
											backgroundColor: COLORS[index % COLORS.length],
										}}
									/>
								</div>
								<div className="specific-issues">
									{category.specificIssues.map((issue, i) => (
										<div key={i} className="issue-item">
											<span>{issue.name}</span>
											<span>{issue.count.toLocaleString()}</span>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="chart-container">
					<h3>Daily Friendship Growth (Last 7 Days)</h3>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart data={stats.dailyGrowth}>
							<CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
							<XAxis dataKey="date" stroke="#6b7280" tick={{ fontSize: 12 }} />
							<YAxis stroke="#6b7280" tick={{ fontSize: 12 }} />
							<Tooltip
								contentStyle={{
									background: '#fff',
									border: '1px solid #e5e7eb',
									borderRadius: '8px',
								}}
							/>
							<Bar dataKey="count" fill="#4f46e5" radius={[4, 4, 0, 0]} name="New Friendships" />
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	)
}

export default FriendshipsSection
