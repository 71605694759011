import React, { useState } from 'react'
import { IoClose, IoInfinite } from 'react-icons/io5'
import './ActivationModal.css'

const ActivationModal = ({ isOpen, onClose, onActivate, expertName }) => {
    const [durationType, setDurationType] = useState('preset')
    const [presetDuration, setPresetDuration] = useState('30')
    const [customDays, setCustomDays] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        let days
        
        if (durationType === 'infinite') {
            days = 36500 // 100 years (effectively infinite)
        } else if (durationType === 'preset') {
            days = parseInt(presetDuration)
        } else {
            days = parseInt(customDays)
        }
        
        onActivate(days)
    }

    if (!isOpen) return null

    return (
        <div className="modal-overlay">
            <div className="activation-modal">
                <div className="modal-header">
                    <h2>Activate Expert Account</h2>
                    <button className="close-button" onClick={onClose} aria-label="Close modal">
                        <IoClose />
                    </button>
                </div>

                <div className="expert-name">
                    Set activation period for <strong>{expertName}</strong>
                </div>
                
                <form onSubmit={handleSubmit} className="form-container">
                    <div className="form-section">
                        <div className="form-section-header">
                            <div className="indicator"></div>
                            <h3>Duration Type</h3>
                        </div>
                        
                        <div className="radio-group">
                            <div className={`radio-option ${durationType === 'preset' ? 'selected' : ''}`}>
                                <input
                                    type="radio"
                                    id="preset"
                                    className="radio-input"
                                    value="preset"
                                    checked={durationType === 'preset'}
                                    onChange={(e) => setDurationType(e.target.value)}
                                />
                                <label htmlFor="preset" className="radio-label">
                                    Preset Duration
                                </label>
                            </div>

                            <div className={`radio-option ${durationType === 'custom' ? 'selected' : ''}`}>
                                <input
                                    type="radio"
                                    id="custom"
                                    className="radio-input"
                                    value="custom"
                                    checked={durationType === 'custom'}
                                    onChange={(e) => setDurationType(e.target.value)}
                                />
                                <label htmlFor="custom" className="radio-label">
                                    Custom Duration
                                </label>
                            </div>

                            <div className={`radio-option ${durationType === 'infinite' ? 'selected' : ''}`}>
                                <input
                                    type="radio"
                                    id="infinite"
                                    className="radio-input"
                                    value="infinite"
                                    checked={durationType === 'infinite'}
                                    onChange={(e) => setDurationType(e.target.value)}
                                />
                                <label htmlFor="infinite" className="radio-label">
                                    Permanent <IoInfinite style={{ marginLeft: '0.5rem' }} />
                                </label>
                            </div>
                        </div>
                    </div>

                    {durationType === 'preset' && (
                        <div className="form-group">
                            <label htmlFor="preset-duration" className="form-label">
                                Preset Duration
                            </label>
                            <select 
                                id="preset-duration"
                                className="form-select"
                                value={presetDuration} 
                                onChange={(e) => setPresetDuration(e.target.value)}
                            >
                                <option value="30">30 days</option>
                                <option value="90">90 days</option>
                                <option value="180">6 months</option>
                                <option value="365">12 months</option>
                            </select>
                            <span className="helper-text">Select a predefined duration period</span>
                        </div>
                    )}

                    {durationType === 'custom' && (
                        <div className="form-group">
                            <label htmlFor="custom-days" className="form-label required">
                                Number of days
                            </label>
                            <input
                                id="custom-days"
                                type="number"
                                className="form-input"
                                min="1"
                                max="365"
                                value={customDays}
                                onChange={(e) => setCustomDays(e.target.value)}
                                placeholder="Enter number of days (1-365)"
                                required
                            />
                            <span className="helper-text">Enter a custom number of days (maximum 365)</span>
                        </div>
                    )}

                    <div className="modal-actions">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-primary"
                        >
                            Activate Account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ActivationModal 