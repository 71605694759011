import React, { useState, useEffect } from 'react'
import {
	LineChart,
	Line,
	PieChart,
	Pie,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	BarChart,
	Bar,
} from 'recharts'
import { FiUsers, FiMessageSquare, FiHeart } from 'react-icons/fi'
import axios from 'axios'
import './Overview.css'
import { useAuth } from '../../../context/AuthContext'
import { adminApi } from '../../../utils/adminApi'

const Overview = () => {
	const { user } = useAuth()
	const [stats, setStats] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const COLORS = ['#4f46e5', '#3b82f6', '#06b6d4', '#10b981', '#8b5cf6']

	useEffect(() => {
		fetchStats()
	}, [])

	const fetchStats = async () => {
		try {
			const data = await adminApi.getStats()
			setStats(data)
		} catch (error) {
			console.error('Error fetching stats:', error)
			setError('Failed to load statistics')
		} finally {
			setLoading(false)
		}
	}

	if (loading)
		return (
			<div className="loading-container">
				<span className="loading-text">Loading overview...</span>
			</div>
		)
	if (error) return <div className="error-message">{error}</div>

	return (
		<div className="overview">
			<div className="stats-cards">
				<div className="stat-card">
					<div className="stat-icon-wrapper">
						<FiUsers className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>Total Users</h3>
						<p>{stats.users.total.toLocaleString()}</p>
					</div>
				</div>

				<div className="stat-card">
					<div className="stat-icon-wrapper messages">
						<FiMessageSquare className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>Total Messages</h3>
						<p>{stats.messages.total.toLocaleString()}</p>
					</div>
				</div>

				<div className="stat-card">
					<div className="stat-icon-wrapper friendships">
						<FiHeart className="stat-icon" />
					</div>
					<div className="stat-info">
						<h3>Total Friendships</h3>
						<p>{stats.friendships.total.toLocaleString()}</p>
					</div>
				</div>
			</div>

			<div className="charts-grid">
				<div className="chart-container">
					<h3>User Growth (Last 7 Days)</h3>
					<ResponsiveContainer width="100%" height={300}>
						<LineChart data={stats.users.dailyGrowth}>
							<CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
							<XAxis dataKey="date" stroke="#6b7280" tick={{ fontSize: 12 }} />
							<YAxis stroke="#6b7280" tick={{ fontSize: 12 }} />
							<Tooltip
								contentStyle={{
									background: '#fff',
									border: '1px solid #e5e7eb',
									borderRadius: '8px',
								}}
							/>
							<Line
								type="monotone"
								dataKey="count"
								stroke="#4f46e5"
								strokeWidth={2}
								dot={{ stroke: '#4f46e5', strokeWidth: 2, r: 4 }}
								activeDot={{ r: 6 }}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>

				<div className="chart-container">
					<h3>Friendship Categories</h3>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							data={stats.friendships.categories}
							layout="vertical"
							margin={{ top: 5, right: 30, left: 100, bottom: 5 }}>
							<CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
							<XAxis type="number" stroke="#6b7280" tick={{ fontSize: 12 }} />
							<YAxis type="category" dataKey="name" stroke="#6b7280" tick={{ fontSize: 12 }} width={90} />
							<Tooltip
								contentStyle={{
									background: '#fff',
									border: '1px solid #e5e7eb',
									borderRadius: '8px',
								}}
								formatter={value => [value.toLocaleString(), 'Friendships']}
							/>
							<Bar dataKey="count" fill="#4f46e5" radius={[0, 4, 4, 0]}>
								{stats.friendships.categories.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
								))}
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	)
}

export default Overview
