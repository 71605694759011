import React, { useState } from 'react'
import {
	FiMenu,
	FiX,
	FiHome,
	FiUserCheck,
	FiAlertCircle,
	FiShield,
	FiSettings,
	FiUsers,
	FiFlag,
	FiHeart,
} from 'react-icons/fi'

const DashboardNav = ({ activeSection, setActiveSection }) => {
	const [isNavOpen, setIsNavOpen] = useState(false)

	const navItems = [
		{ id: 'overview', label: 'Overview', icon: <FiHome /> },
		{ id: 'experts', label: 'Experts', icon: <FiUsers /> },
		{ id: 'users', label: 'Users', icon: <FiUserCheck /> },
		{ id: 'reports', label: 'Reports', icon: <FiAlertCircle /> },
		{ id: 'friendships', label: 'Friendships', icon: <FiHeart /> },
		{ id: 'blacklist', label: 'Blacklist', icon: <FiShield /> },
		{ id: 'settings', label: 'Settings', icon: <FiSettings /> },
	]

	const toggleNav = () => {
		setIsNavOpen(!isNavOpen)
		document.body.classList.toggle('scroll-lock')
	}

	const handleNavClick = section => {
		setActiveSection(section)
		if (window.innerWidth <= 1024) {
			setIsNavOpen(false)
			document.body.classList.remove('scroll-lock')
		}
	}

	return (
		<>
			<nav className={`dashboard-nav ${isNavOpen ? 'active' : ''}`}>
				<div className="nav-menu">
					{navItems.map(item => (
						<div
							key={item.id}
							className={`nav-item ${activeSection === item.id ? 'active' : ''}`}
							onClick={() => handleNavClick(item.id)}>
							<span className="nav-icon">{item.icon}</span>
							<span className="nav-label">{item.label}</span>
						</div>
					))}
				</div>
			</nav>
		</>
	)
}

export default DashboardNav
