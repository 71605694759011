import axios from 'axios'

const BASE_URL = 'https://findalike.me/api/admin'

export const adminApi = {
	request: async (endpoint, options = {}) => {
		const user = JSON.parse(localStorage.getItem('userInfo'))

		const config = {
			...options,
			headers: {
				...options.headers,
				'X-User-Id': user?._id,
			},
			withCredentials: true,
		}

		try {
			const response = await axios(`${BASE_URL}${endpoint}`, config)
			return response.data
		} catch (error) {
			console.error(`Admin API Error (${endpoint}):`, error)
			throw error
		}
	},

	getStats: () => adminApi.request('/stats'),
	getFriendshipStats: () => adminApi.request('/friendship-stats'),
	getReports: () => adminApi.request('/reports'),
	getUsers: () => adminApi.request('/users'),
	getExperts: () => adminApi.request('/experts'),
	updateExpertStatus: (expertId, isActive, days = null) =>
		adminApi.request(`/experts/${expertId}/status`, {
			method: 'PUT',
			data: { isActive, activationDuration: days },
		}),
}
