import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import './ContactPage.css'
import { FaEnvelope, FaPhone, FaTwitter, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'

function ContactPage() {
	const [formData, setFormData] = useState({ name: '', email: '', message: '' })
	const { t } = useTranslation()

	const handleChange = e => {
		const { name, value } = e.target
		setFormData({ ...formData, [name]: value })
	}

	const handleSubmit = async e => {
		e.preventDefault()
		try {
			await axios.post('https://findalike.me/api/send-email', formData)
			toast.success(t('contact.messageSentSuccess'))
			setFormData({ name: '', email: '', message: '' })
		} catch (error) {
			console.error('Error sending message:', error)
			toast.error(t('contact.messageSentError'))
		}
	}

	return (
		<>
			<div className="contact-page">
				<ToastContainer />
				<div className="contact__headline">
					<h2>{t('contact.headline')}</h2>
					<p>{t('contact.subheadline')}</p>
				</div>
				<div className="contact__container">
					<div className="contact__box">
						<div className="contact__box-headline">
							<h3>{t('contact.getInTouch')}</h3>
							<p>{t('contact.contactFormPrompt')}</p>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="contact__form-container">
								<div className="input-container">
									<label>{t('contact.nameLabel')}</label>
									<input
										type="text"
										name="name"
										placeholder={t('contact.namePlaceholder')}
										value={formData.name || ''}
										onChange={handleChange}
									/>
								</div>
								<div className="input-container">
									<label>{t('contact.emailLabel')}</label>
									<input
										type="email"
										name="email"
										placeholder={t('contact.emailPlaceholder')}
										value={formData.email || ''}
										onChange={handleChange}
									/>
								</div>
								<div className="input-container">
									<label>{t('contact.messageLabel')}</label>
									<textarea
										name="message"
										placeholder={t('contact.messagePlaceholder')}
										value={formData.message || ''}
										onChange={handleChange}
									/>
								</div>
							</div>
							<button className="contact__button">{t('contact.sendMessageButton')}</button>
						</form>
					</div>
					<div className="contact__info">
						<div className="info-box">
							<p className="info-box-top">findalikeme@gmail.com</p>
							<div className="contact-icon">
								<FaEnvelope className="icon" />
							</div>
							<div className="info-box-text">
								<h5>{t('contact.writeToUs')}</h5>
								<p>{t('contact.writeToUsPrompt')}</p>
							</div>
						</div>
						<div className="info-box">
							<p className="info-box-top">+48 698 404 252</p>
							<div className="contact-icon">
								<FaPhone className="icon" />
							</div>
							<div className="info-box-text">
								<h5>{t('contact.callUs')}</h5>
								<p>{t('contact.callUsPrompt')}</p>
							</div>
						</div>
						<div className="info-box">
							<p className="info-box-top">@FindAlikeMe</p>
							<div className="contact-icon">
								<FaTiktok className="icon" />
							</div>
							<div className="info-box-text">
								<h5>{t('contact.followUsTiktok')}</h5>
								<p>{t('contact.followUsTiktokPrompt')}</p>
							</div>
						</div>
						<div className="info-box-social">
							<a className="social-box" href="https://www.tiktok.com/@findalikeme" target="_blank">
								<FaTiktok className="icon" />
							</a>
							<a className="social-box" href="https://www.instagram.com/findalikeme/" target="_blank">
								<FaInstagram className="icon" />
							</a>
							<div className="social-box disabled">
								<FaTwitter className="icon" />
							</div>
							<div className="social-box disabled">
								<FaFacebook className="icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default ContactPage
